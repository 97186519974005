<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <v-img
      v-if="image"
      :class="classes"
      :disabled="disabled"
      :style="style"
      :height="height"
      :width="width"
      :alt="alt"
      :src="image"
      @click="onClick"
    />
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeImage',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  data() {
    return {
      image: false
    }
  },
  computed: {
    elementStyle() {
      let style = ''
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    classes() {
      let classes = this.state.classes || ''
      const settings = this.cell.settings
      if (/^true|1$/i.test(this.value)) {
        classes += 'image-cell-selected '
      }
      if (settings.image_clickable) {
        classes += 'image-cell-clickable '
      }
      return classes
    },
    disabled() {
      return this.state.disabled
    },
    height() {
      return this.cell.settings.image_height || 'auto'
    },
    width() {
      return this.cell.settings.image_width || 'auto'
    },
    style() {
      let style = ''
      const outlineColor = this.dataViewer.outline_color
      if (outlineColor) {
        style += `outline: 1px solid ${outlineColor};`
      }
      return style || ''
    },
    alt() {
      return this.cell.settings.image_alternate_text || ''
    },
    value() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      return builder.getCellValue(index, this.cell, this.clone, {})
    }
  },
  async mounted() {
    this.image = await this.getImage()
  },
  methods: {
    onClick() {
      if (this.disabled) {
        return
      }
      const settings = this.cell.settings
      if (!settings.image_clickable) {
        return
      }
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const previousValue = builder.getCellValue(index, this.cell, this.clone, false)
      const value = !previousValue
      builder.evalValues({
        index: index,
        dataEditor: this.cell,
        dataViewer: this.dataViewer,
        cloneIndex: this.clone,
        encode: false,
        saveCallback: undefined,
        ignoreAutoSaveSetting: false,
        value: value,
        previousValue: previousValue
      })
    },
    async getImage() {
      const settings = this.cell.settings
      let image = false
      if (settings.image_data) {
        try {
          image = atob(settings.image_data)
        } catch (ex) {
          // Keine Aktion benötigt
        }
      } /* else if (settings.image_link) {
        if (settings.image_link_analysis) {
          image = await this.getAnalysisLink()
        }
      } */
      return image
    } /* ,
    async getAnalysisLink() {
      const data = this.data.relationData
      if (!data) {
        return false
      }
      const analysisId = data.analysisId
      if (!Number.isInteger(analysisId) || !analysisId) {
        return false
      }
      const link = await this.$axios.get('/hazardanalysis/getLink', {
        params: {
          id: analysisId
        }
      }).then((response) => {
        return (response.data || {}).data || false
      }).catch((response) => {
        return false
      })
      return link
    } */
  }
}
</script>

<style>
  .checklist-viewer .checklist-content
  .element-content > div {
    margin: 0 auto;
  }

  .checklist-viewer .checklist-content
  .element-content .image-cell-clickable {
    cursor: pointer;
  }

  .checklist-viewer .checklist-content
  .element-content .image-cell-selected {
    border: 1px solid #1c94c4;
  }
</style>
